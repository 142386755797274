import { ApolloClient, ApolloLink } from '@apollo/client';
import { IncomingHttpHeaders } from 'http';
import { createUploadLink } from 'apollo-upload-client';
import { endpointLink, errorLink } from './links';
import cache from './cache';

const createApolloClient = (headers: IncomingHttpHeaders | null = null) => {
    const enhancedFetch = async (url: RequestInfo, init: RequestInit) => {
        let customHeaders = {};

        if (process.env.NEXT_PUBLIC_BASIC_AUTH === 'true') {
            customHeaders = {
                ...headers,
                Authorization: `Basic ${process.env.NEXT_PUBLIC_BASIC_AUTH_TOKEN}`,
            };
        }

        // @note Obtain the client's cookie, if the request is done from the server you have to pass the cookie in the Headers from the context
        const accessToken = (headers as { 'X-Auth-Token'?: string })?.['X-Auth-Token'];

        if (accessToken) {
            customHeaders = {
                ...customHeaders,
                'X-Auth-Token': accessToken,
            };
        }

        return fetch(url, {
            ...init,
            headers: {
                ...init.headers,
                ...customHeaders,
                'Access-Control-Allow-Origin': `*`,
                // here we pass the cookie along for each request
                Cookie: headers?.cookie ?? ``,
                'X-Client-Id': process.env.NEXT_PUBLIC_CLIENT_ID ?? '',
                'X-Client-Secret': process.env.NEXT_PUBLIC_CLIENT_SECRET ?? ''
            },
        }).then((response) => response);
    };

    return new ApolloClient({
        // SSR only for Node.js
        ssrMode: typeof window === 'undefined',
        link: ApolloLink.from([
            errorLink,
            // this uses apollo-link-http under the hood, so all the options here come from that package
            createUploadLink({
                ...endpointLink,
                fetch: enhancedFetch,
            }),
        ]),
        cache,
    });
};

export default createApolloClient;
