import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { PagePropsWithApolloState } from './types';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

const addApolloState = (client: ApolloClient<NormalizedCacheObject>, pageProps: PagePropsWithApolloState): PagePropsWithApolloState => {
    if (pageProps) {
        pageProps[APOLLO_STATE_PROP_NAME] = client.cache.extract();
    }

    return pageProps;
};

export default addApolloState;
