import { onError } from '@apollo/client/link/error';

export const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations?.[0]}, Path: ${path?.[0]}`);
        });
    if (networkError) console.log(`[Network error]: ${networkError}. Backend is unreachable. Is it running?`);
});


export const endpointLink = {
    uri: `${process.env.NEXT_PUBLIC_DOMAIN_URL || 'http://localhost:3000/'}api/graphql`,
    fetchOptions: {
        mode: 'cors',
    },
    credentials: 'include',
};
