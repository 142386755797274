import React from 'react';
import TagManager from 'react-gtm-module';
import { appWithTranslation } from 'next-i18next';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '@/lib/apollo-client';
import { AppPropsWithLayout } from '@/types';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-input-2/lib/bootstrap.css';
import 'assets/styles/sweetalert2.css';
import 'assets/styles/swiper.css';
import 'assets/styles/custom.css';
import 'assets/styles/datepicker.css';

const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';

const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || '',
};

const AppRoot = ({ Component, pageProps }: AppPropsWithLayout) => {
    const apolloClient = useApollo(pageProps);

    // Use the layout defined at the page level, if available
    const getLayout = Component.getLayout || ((page) => page);

    React.useEffect(() => {
        if (isProduction) {
            TagManager.initialize(tagManagerArgs);
        }
    }, []);

    if (!apolloClient) return <></>;

    return <ApolloProvider client={apolloClient}>{getLayout(<Component {...pageProps} />)}</ApolloProvider>;
};

export default appWithTranslation(AppRoot);
