import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { IInitializeApollo } from './types';
import createApolloClient from './createApolloClient';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

const initializeApollo = ({ initializeApolloData = { initialState: null } }: { initializeApolloData?: IInitializeApollo }) => {
    const { initialState } = initializeApolloData;

    const apolloClientInstance = apolloClient ?? createApolloClient();

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = apolloClientInstance.extract();

        // Merge the existing cache into data passed from getStaticProps/getServerSideProps
        const data = merge(initialState, existingCache, {
            // Combine arrays using object equality (like in sets)
            arrayMerge: (destinationArray, sourceArray): unknown[] => [...sourceArray, ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s)))],
        });

        // Restore the cache with the merged data
        apolloClientInstance.cache.restore(data);
    }

    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return apolloClientInstance;

    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = apolloClientInstance;

    return apolloClientInstance;
};

export default initializeApollo;
