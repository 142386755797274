import React from 'react';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import { APOLLO_STATE_PROP_NAME } from './addApolloState';
import initializeApollo from './initializeApollo';
import cache from './cache';
import { PagePropsWithApolloState } from './types';

const useApollo = (pageProps: PagePropsWithApolloState) => {
    const state = pageProps[APOLLO_STATE_PROP_NAME];

    const initialState = typeof state !== 'undefined' && state !== null ? state : null;

    React.useEffect(() => {
        void persistCache({
            cache,
            storage: new LocalStorageWrapper(window.localStorage),
            trigger: 'write',
            debounce: 1000,
            key: 'apollo-local-data',
            serialize: true,
            maxSize: 10485760,
            debug: true,
        });
    }, []);


    return React.useMemo(() => initializeApollo({ initializeApolloData: { initialState } }), [initialState]);
};

export default useApollo;
